import React from "react"
//import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"
import { FormattedMessage, FormattedHTMLMessage,injectIntl, Link } from "gatsby-plugin-intl"
import Banner from "../components/banner"



const NotFoundPage = ({ intl }) => (
<>
    <Layout>
    <SEO
      lang={intl.locale}
      title={intl.formatMessage({ id: "Pages.404.title" })}
      description={intl.formatMessage({ id: "Pages.404.description" })}
      created={intl.created} 
      pathname={intl.pathname}      
      og_type={intl.formatMessage({ id: "Pages.404.og_type" })}      
      isBlogPost={false}
      norobots={true}      
    />
    <Banner/>
    <h1>
      <FormattedMessage id="Pages.404.mainHeader" defaultMessage="Page not found" />
    </h1>
    <p>
      <FormattedMessage id="Pages.404.mainSubheader" defaultMessage="access to non existing page"  />
    </p>
    <p><Link to="/"><FormattedHTMLMessage id="Pages.404.gotoHome" defaultMessage=""  /></Link> </p>
    <p><Link to="/blog/"> <FormattedHTMLMessage id="Pages.404.gotoBLOG" defaultMessage=""  /></Link></p>
        
        
    
    </Layout>
</>
)

export default injectIntl(NotFoundPage)   